import { render, staticRenderFns } from "./SclvAvatar.vue?vue&type=template&id=e8ae900c"
import script from "./SclvAvatar.vue?vue&type=script&lang=js"
export * from "./SclvAvatar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@2.8.8_vue-temp_pxjgtvcgzwdiz5am2g337k7qpe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports