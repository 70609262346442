var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SclvForm',{staticClass:"w-full md:w-[420px]",on:{"submit":function($event){_vm.isOtpRequested ? _vm.onSubmit() : _vm.requestOtp()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('Modal',{attrs:{"title":"Cannot create new business","width":"w-[400px]","label-ok":"OK","show-close-button":false},on:{"on-ok":function($event){_vm.businessCountAlert.check = false}},model:{value:(_vm.businessCountAlert.check),callback:function ($$v) {_vm.$set(_vm.businessCountAlert, "check", $$v)},expression:"businessCountAlert.check"}},[_c('div',{staticClass:"text-[12px] font-normal leading-4"},[_vm._v("\n      "+_vm._s(_vm.businessCountAlert.errorMessage)+"\n    ")])]),_vm._v(" "),(!_vm.isOtpRequested)?_c('div',{staticClass:"w-full"},[_c('TextField',{attrs:{"rules":"required","label":"Nama Bisnis Kamu","placeholder":"Contoh: Mawar Store"},model:{value:(_vm.accountHolder),callback:function ($$v) {_vm.accountHolder=$$v},expression:"accountHolder"}}),_vm._v(" "),_c('TextField',{attrs:{"rules":"required|subdomain","label":"Subdomain","placeholder":"Contoh: mawarstore","custom-error":_vm.isUsernameExist
          ? 'Username tidak tersedia. Silakan coba username yang lain.'
          : '',"custom-success":_vm.isUsernameAvailable ? 'Selamat, username dapat digunakan.' : '',"append":".scalev.id"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_vm._v(" "),_c('TextField',{attrs:{"placeholder":"example@example.com","label":"Email Bisnis","rules":"required|email|no_emoji"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('TextField',{attrs:{"type":"number","placeholder":"cont. 62812881199619","label":"Nomor Telepon","rules":"required"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_vm._v(" "),_c('SclvTextarea',{attrs:{"label":"Alamat Bisnis","placeholder":"Tulis Alamat ...","height":"h-[64px]","rules":"required"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_vm._v(" "),_c('SclvSelect',{attrs:{"rules":"required","placeholder":"Pilih Kabupaten / Kota","vid":"Pilih Kabupaten / Kota","name":"Pilih Kabupaten / Kota","url-options":"/location/","base-url":_vm.$config.apiUrlV2,"search-query":"search","value-field":"id","is-searchable":"","default-list-height":"","placeholder-search":"Cari Kota, Kabupaten atau Provinsi"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var selected = ref.selected;
          var placeholder = ref.placeholder;
          var selectedOption = ref.selectedOption;
return [_c('div',{staticClass:"flex w-full flex-col pr-[16px]",class:{ 'text-black-400': selected === placeholder }},[_c('span',{staticClass:"truncate"},[_vm._v("\n            "+_vm._s(selected === placeholder
                ? placeholder
                : ((selectedOption.subdistrict_name) + ", " + (selectedOption.city_name) + ", " + (selectedOption.province_name)))+"\n          ")])])]}},{key:"list-item",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"flex w-full items-center p-[8px]"},[_vm._v("\n          "+_vm._s(((item.subdistrict_name) + ", " + (item.city_name) + ", " + (item.province_name)))+"\n        ")])]}}],null,true),model:{value:(_vm.subdistrictId),callback:function ($$v) {_vm.subdistrictId=$$v},expression:"subdistrictId"}}),_vm._v(" "),(_vm.subdistrictId !== null)?_c('SclvSelect',{attrs:{"rules":"required","placeholder":"Pilih Kode Pos","vid":"Pilih Kode Pos","name":"Pilih Kode Pos","options":_vm.postalCodes,"value-field":"postal_code","text-field":"postal_code","is-searchable":"","default-list-height":"","placeholder-search":"Cari Kode Pos"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var selected = ref.selected;
                var placeholder = ref.placeholder;
return [_c('div',{staticClass:"flex w-full flex-col pr-[16px]",class:{ 'text-black-400': selected === placeholder }},[_c('span',{staticClass:"truncate"},[_vm._v("\n            "+_vm._s(selected === placeholder
                ? placeholder
                : ("Kode Pos: " + _vm.postalCodeSelected))+"\n          ")])])]}}],null,true),model:{value:(_vm.postalCodeSelected),callback:function ($$v) {_vm.postalCodeSelected=$$v},expression:"postalCodeSelected"}}):_vm._e()],1):_c('div',{staticClass:"flex w-full flex-col"},[(_vm.isOtpRequested)?_c('label',{staticClass:"mb-[10px] text-[12px] font-medium text-gray-800"},[_vm._v("\n      OTP\n    ")]):_vm._e(),_vm._v(" "),(_vm.isOtpRequested)?_c('div',{staticClass:"flex w-full justify-center"},[_c('OtpInput',{staticClass:"mx-auto",attrs:{"input-classes":"otp-input","separator":"","num-inputs":6,"should-auto-focus":true,"is-input-num":true},on:{"on-change":function($event){_vm.otp = $event},"on-complete":function($event){_vm.otp = $event}}})],1):_vm._e(),_vm._v(" "),(_vm.isOtpRequested)?_c('SclvButton',{staticClass:"mt-2 text-[12px]",attrs:{"color":"text-primary","text":""},on:{"click":_vm.requestOtp}},[_vm._v("\n      Request OTP Again\n    ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex justify-end space-x-[16px] pt-[14px]"},[(_vm.$mq !== 'sm')?_c('SclvButton',{attrs:{"small":"","color":"text-red-900 bg-black-50"},on:{"click":function($event){_vm.isOtpRequested
          ? (_vm.isOtpRequested = false)
          : _vm.onCancel({ accountHolder: _vm.accountHolder, username: _vm.username, phone: _vm.phone })}}},[_vm._v("\n      "+_vm._s(_vm.isOtpRequested ? 'Kembali' : 'Batal')+"\n    ")]):_vm._e(),_vm._v(" "),_c('SclvButton',{staticClass:"w-full md:w-auto",class:{ 'shadow-lg': valid },attrs:{"small":"","type":"submit","color":"bg-primary text-white","disabled":(!_vm.isOtpRequested &&
          (!valid ||
            (valid && _vm.isUsernameExist) ||
            (valid && !_vm.isUsernameAvailable))) ||
        (_vm.isOtpRequested && !_vm.otp)}},[_vm._v("\n      "+_vm._s(_vm.isOtpRequested ? 'Buat Bisnis' : 'Kirim OTP')+"\n    ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }