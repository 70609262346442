//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { getTimeAgoOrFormattedDate } from '@/utils/helpers'

export default {
  filters: {
    getTimeAgoOrFormattedDate
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    created: {
      type: String,
      default: null
    },
    idNotif: {
      type: Number,
      default: null
    },
    orderId: {
      type: Number,
      default: null
    },
    unread: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('subscription', [
      'getDetailSubscription',
      'getOpenSubscriptionOrder'
    ]),
    ...mapGetters('xpTransaction', ['getBalance']),
    icon() {
      const iconx = {
        new_order: 'shopping-cart-sclvico', // keranjang
        to_confirm: 'cash-sclvico', // uang
        confirmed: 'dollar-sclvico', // dollar
        payment_reminder: 'ic-calendar',
        business_subscription: 'card-sclvico',
        mark_unpaid: 'exclamation-2-sclvico'
      }

      return iconx[this.type] || 'shopping-cart-sclvico'
    },
    iconBgColor() {
      const iconx = {
        new_order: 'bg-yellow-500', // keranjang
        to_confirm: 'bg-primary', // uang
        confirmed: 'bg-green-200', // dollar
        payment_reminder: 'bg-yellow-500',
        business_subscription: 'bg-yellow-500',
        mark_unpaid: 'bg-red-600'
      }

      return iconx[this.type] || 'bg-green-500'
    }
  },
  methods: {
    async goToDetail() {
      this.$store.dispatch('notification/markAsRead', this.idNotif)
      this.$emit('click')

      if (this.type === 'payment_reminder') {
        if (this.getOpenSubscriptionOrder) {
          this.$nuxt.$loading.start(true)
          window.location.href = await this.reminderTargetUrl()
          return
        }

        this.$router.push('/setting/billing')
        return
      }

      if (this.type === 'mark_unpaid') {
        if (this.getOpenSubscriptionOrder) {
          this.$nuxt.$loading.start(true)
          window.location.href = await this.reminderTargetUrl()
          return
        }

        if (this.getDetailSubscription.status === 'active') {
          this.$router.push('/setting/billing')
          return
        }

        await this.maybeReactivateSubscription()
        return
      }

      if (this.type === 'business_subscription') {
        this.$router.push('/setting/billing')
        return
      }

      this.$router.push(`/order/${this.orderId}`)
    },
    async maybeReactivateSubscription() {
      this.$nuxt.$loading.start(true)

      if (this.getOpenSubscriptionOrder) {
        window.location.href = await this.reminderTargetUrl()
        return
      }

      try {
        if (this.getDetailSubscription == null) {
          window.location.href = `/setting/billing?isPricePlanShown=true`
          return
        }

        const pricingPlanCode =
          this.getDetailSubscription?.current_pricing_plan?.code
        const payloadPayment = {
          idSubscription: this.getDetailSubscription?.id,
          pricingPlanCode
        }
        await this.$store.dispatch(
          'subscription/partialUpdateSubscription',
          payloadPayment
        )

        window.location.href = await this.reminderTargetUrl()
      } catch (error) {
        this.$errorHandler(error)
        this.$nuxt.$loading.finish()
      }
    },
    async reminderTargetUrl() {
      if (this.getOpenSubscriptionOrder == null) {
        return `/setting/billing?isPricePlanShown=true`
      }

      try {
        await Promise.all([
          this.$store.dispatch('xpTransaction/fetchBalance'),
          this.$store.dispatch('xpTransaction/fetchHoldingBalance')
        ])
      } catch {}
      if (this.getBalance >= this.getOpenSubscriptionOrder.amount) {
        return `/setting/billing?subscriptionOrderId=${this.getOpenSubscriptionOrder.id}`
      } else {
        return this.getOpenSubscriptionOrder.xendit_invoice_url
      }
    }
  }
}
