export default {
  BASE_TERMS_VERSION: {
    '2024-11-19': [
      'Dokumen dibuat lebih ringkas dengan memindahkan ketentuan teknis COD, COD Fee, dan Payment Gateway ke dokumen terpisah yang khusus membahas E-Payment bagi yang akan menggunakan fitur-fitur tersebut.',
      'Ditambahkan referensi ke "Halaman Paket" untuk informasi harga dan layanan.',
      'Ditambahkan bagian integrasi Meta Custom Audience untuk Facebook Ads.',
      'Ditambahkan aturan perubahan harga yang harus diinfokan minimal 14 hari sebelumnya.'
    ]
  },
  PAYMENT_TERMS_VERSION: {
    '2024-11-19': [
      'Penambahan penjelasan untuk proses verifikasi identitas sebelum menggunakan fitur E-Payment.',
      'Penjelasan bahwa setiap bisnis perseorangan yang dibuat oleh pengguna yang terverifikasi akan otomatis terverifikasi pula.',
      'Nama rekening pencairan harus sesuai dengan nama profil yang terverifikasi, dengan tetap mempertimbangkan adanya pemotongan nama oleh sistem perbankan.',
      'Menambahkan referensi ke halaman khusus fitur dan harga serta halaman khusus daftar transaction fee.',
      'Untuk pesanan yang menggunakan E-Payment, terdapat penambahan Scalev Fee yang dihitung dari persentase gross revenue.'
    ]
  },
  TOC_VERSION: {
    '2024-11-19': [
      'Penambahan penjelasan untuk proses verifikasi bisnis sebelum menggunakan fitur E-Payment.',
      'Penjelasan bahwa setiap bisnis PT harus terverifikasi secara sendiri-sendiri untuk dapat menggunakan fitur E-Payment.',
      'Nama rekening pencairan harus sesuai dengan nama bisnis yang terverifikasi, dengan tetap mempertimbangkan adanya pemotongan nama oleh sistem perbankan.',
      'Menambahkan referensi ke halaman khusus fitur dan harga serta halaman khusus daftar transaction fee.',
      'Untuk pesanan yang menggunakan E-Payment, terdapat penambahan Scalev Fee yang dihitung dari persentase gross revenue.'
    ]
  },
  AFFILIATE_TERMS_VERSION: {
    '2024-11-19': [
      'Untuk dapat menjadi affiliator, pengguna harus terverifikasi terlebih dahulu.',
      'Nama rekening pencairan harus sesuai dengan nama profil yang terverifikasi, dengan tetap mempertimbangkan adanya pemotongan nama oleh sistem perbankan.'
    ]
  },
  BASE_PRIVACY_VERSION: {
    '2023-04-27': []
  },
  VERIFICATION_PRIVACY_VERSION: {
    '2024-11-19': []
  },
  MANUAL_VERIFICATION_PRIVACY_VERSION: {
    '2024-11-28': []
  }
}
