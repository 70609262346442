import {
  encodeQueryData,
  initQueryParams,
  isEmpty,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  member: [],
  loading: false,
  location: [],
  warehouse: [],
  metaWarehouse: {
    pageSize: 25,
    lastId: undefined
  },
  metaWarehousePartner: {
    pageSize: 25,
    lastId: undefined
  },
  retrieveWarehouse: [],
  warehousePartner: [],
  retrieveWarehousePartner: [],
  warehouseCombined: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  // ++Start Warehouse Section++ //

  // Create Warehouse
  async createWarehouse(_, payload) {
    try {
      const {
        name,
        address,
        subdistrictModel,
        isSameCityDelivery,
        sameCityDeliveryFee,
        warehouseAdmins,
        isOpen,
        postalCode
      } = payload
      const resCreateWarehouse = await this.$axios.$post(
        `${this.$config.apiUrlV2}/warehouse/`,
        {
          name,
          is_open: isOpen,
          warehouse_admin_ids: warehouseAdmins,
          warehouse_address: {
            location_id: subdistrictModel,
            address,
            postal_code: postalCode
          },
          is_same_city_delivery: isSameCityDelivery,
          same_city_delivery_fee: sameCityDeliveryFee
        }
      )
      return resCreateWarehouse.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllBusinessDefaultWarehouse({ commit }, businessId) {
    try {
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/warehouse?is_default=true&business_id=${businessId}`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Get All Warehouse
  async fetchAllWarehouse({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_WAREHOUSE', [])
      commit('SET_META_WAREHOUSE', {
        pageSize: 25,
        lastId: undefined
      })
    }
    const { pageSize, lastId } = state.metaWarehouse
    const initQuery = initQueryParams({
      page_size: isFirst ? 25 : pageSize,
      last_id: isFirst ? undefined : lastId,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/warehouse/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const allWarehouse = [...state.warehouse, ...res.data.results]
      commit('SET_WAREHOUSE', allWarehouse)
      const nextMeta = {
        pageSize,
        lastId: res.data.results[res.data.results.length - 1]?.id || undefined
      }
      commit('SET_META_WAREHOUSE', nextMeta)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Retrieve Warehouse
  async retrieveWarehouse({ commit }, idWarehouse) {
    const url = `${this.$config.apiUrlV2}/warehouse/${idWarehouse}/`
    try {
      const retrieveWarehouse = await this.$axios.$get(url)
      commit('SET_RETRIEVE_WAREHOUSE', retrieveWarehouse.data)
      return retrieveWarehouse.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // Update Warehouse
  async updateWarehouse(_, { idWarehouse, ...payload }) {
    try {
      const {
        name,
        address,
        subdistrictModel,
        isSameCityDelivery,
        sameCityDeliveryFee,
        warehouseAdmins,
        isOpen,
        postalCode
      } = payload
      const resUpdateWarehouse = await this.$axios.$put(
        `${this.$config.apiUrlV2}/warehouse/${idWarehouse}/`,
        {
          name,
          is_open: isOpen,
          warehouse_admin_ids: warehouseAdmins,
          warehouse_address: {
            location_id: subdistrictModel,
            address,
            postal_code: postalCode
          },
          is_same_city_delivery: isSameCityDelivery,
          same_city_delivery_fee: sameCityDeliveryFee
        }
      )
      return resUpdateWarehouse.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credenstials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Partial Update Warehouse
  async partialUpdateWarehouse(_, { idWarehouse, ...payload }) {
    try {
      const {
        name,
        address,
        subdistrictModel,
        isSameCityDelivery,
        sameCityDeliveryFee,
        isOpen
      } = payload
      const resPartialUpdateWarehouse = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/warehouse/${idWarehouse}/`,
        {
          name,
          is_open: isOpen,
          warehouse_address: {
            location_id: subdistrictModel,
            address
          },
          is_same_city_delivery: isSameCityDelivery,
          same_city_delivery_fee: sameCityDeliveryFee
        }
      )
      return resPartialUpdateWarehouse.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credenstials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Delete Warehouse
  async destroyWarehouse(_, idWarehouse) {
    try {
      const resDestroyWarehouse = await this.$axios.$delete(
        `${this.$config.apiUrlV2}/warehouse/${idWarehouse}/`
      )
      return resDestroyWarehouse
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async generateCaOrigin(_, payload) {
    const { id, courierAggregatorCode } = payload
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/warehouse/${id}/generate-ca-origin`,
        {
          courier_aggregator_code: courierAggregatorCode
        }
      )
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async generateCaOriginPartner(_, payload) {
    const { id, courierAggregatorCode } = payload
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/warehouse-partner/${id}/generate-ca-origin`,
        {
          courier_aggregator_code: courierAggregatorCode
        }
      )
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // Create Warehouse Partner
  async createWarehousePartner(_, { idWarehouse, uniqueId }) {
    const url = `${this.$config.apiUrlV2}/warehouse/${idWarehouse}/warehouse-partner/`
    try {
      const resCreateWarehouse = await this.$axios.$post(url, {
        unique_id: uniqueId
      })
      return resCreateWarehouse
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // reset Warehouse
  resetWarehouse({ commit }) {
    commit('SET_WAREHOUSE_PARTNER', [])
    commit('SET_META_WAREHOUSE_PARTNER', {})
  },

  // Get All Warehouse Partner
  async fetchAllWarehousePartner(
    { commit, state },
    { idWarehouse, isFirst = false }
  ) {
    if (isFirst) {
      commit('SET_WAREHOUSE_PARTNER', [])
      commit('SET_META_WAREHOUSE_PARTNER', {
        pageSize: 25,
        lastId: undefined
      })
    }
    const { pageSize, lastId } = state.metaWarehousePartner
    const initQuery = initQueryParams({
      page_size: isFirst ? 25 : pageSize,
      last_id: isFirst ? undefined : lastId
    })
    const queries = encodeQueryData(initQuery)
    const url = `${this.$config.apiUrlV2}/warehouse/${idWarehouse}/warehouse-partner?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const allWarehousePartner = [
        ...state.warehousePartner,
        ...res.data.results
      ]
      commit('SET_WAREHOUSE_PARTNER', allWarehousePartner)
      const nextMeta = {
        pageSize,
        lastId: res.data.results[res.data.results.length - 1]?.id || undefined
      }
      commit('SET_META_WAREHOUSE_PARTNER', nextMeta)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Fetch Warehouse Partner Not Paginated
  async fetchWarehousePartnerNotPaginated({ commit }, idWarehouse) {
    const url = `${this.$config.apiUrlV2}/warehouse/${idWarehouse}/warehouse-partner/`
    try {
      const retrieveWarehouse = await this.$axios.$get(url)
      commit('SET_WAREHOUSE_PARTNER', retrieveWarehouse.data.results)
      return retrieveWarehouse.data.results
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // Retrieve Warehouse Partner
  async retrieveWarehousePartner(
    { commit },
    { idWarehouse, idWarehousePartner }
  ) {
    const url = `${this.$config.apiUrlV2}/warehouse-partner/${idWarehousePartner}/`
    try {
      const retrieveWarehouse = await this.$axios.$get(url)
      commit('SET_RETRIEVE_WAREHOUSE', retrieveWarehouse.data.results)
      return retrieveWarehouse.data.results
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // Update Warehouse Partner
  async updateWarehousePartner(
    _,
    { idWarehouse, idWarehousePartner, isComply }
  ) {
    try {
      const resUpdateWarehousePartner = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/warehouse-partner/${idWarehousePartner}/`,
        {
          is_comply: isComply
        }
      )
      return resUpdateWarehousePartner.data.results
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credenstials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // Delete Warehouse Partner
  async destroyWarehousePartner(_, { idWarehouse, idWarehousePartner }) {
    try {
      const resDestroyWarehousePartner = await this.$axios.$delete(
        `${this.$config.apiUrlV2}/warehouse-partner/${idWarehousePartner}/`
      )
      return resDestroyWarehousePartner
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async fetchNotPaginated({ commit }) {
    const url = `${this.$config.apiUrlV2}/warehouse/`
    try {
      const warehouseAll = await this.$axios.$get(url)
      commit('SET_WAREHOUSE', warehouseAll.data.results)
      return warehouseAll.data.results
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  // ++End Warehouse Section++ //

  // ++Start Shared Warehouse++ //

  resetState({ commit }) {
    commit('SET_WAREHOUSE', [])
    commit('SET_WAREHOUSE_PARTNER', [])
    commit('SET_META_WAREHOUSE', {
      page: 0
    })
    commit('SET_META_WAREHOUSE_PARTNER', {
      page: 0
    })
  },

  // Fetch Location
  async fetchLocation({ commit }, { query }) {
    commit('SET_LOADING', true)
    let queries = ''
    if (!isEmpty(query)) {
      queries = `?${encodeQueryData(query)}`
    }
    const url = `/location/${queries}`
    try {
      const resSubdistrictList = await this.$axios.$get(url)

      commit('SET_LOCATION', resSubdistrictList.data.results)
      commit('SET_LOADING', false)
      return resSubdistrictList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_LOADING', false)
      throw error
    }
  },

  // Fetch Location by id
  async fetchLocationById({ commit }, id) {
    const url = `/location/${id}/`
    try {
      const resSubdistrictById = await this.$axios.$get(url)
      return resSubdistrictById.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  // ++End Shared Warehouse++ //

  // ++Start Warehouse Business++ //
  async fetchBusinessByUniqueId(_, uniqueId) {
    const url = `business-search/?unique_id=${uniqueId}`
    try {
      const res = await this.$axios.$get(url)
      return res.data.results
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
