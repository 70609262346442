import { mapMutationsHelper, mapGettersHelper } from '@/utils/helpers.js'

export const state = () => ({
  isEmailNotExist: false,
  isEmailChecking: false,
  emailExistMsg: 'Checking',
  user: null
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  setUser({ commit }, user) {
    commit('SET_USER', user)
  },
  async login({ commit }, payload) {
    const { email, password } = payload
    const url = `${this.$config.apiUrlV2}/auth/jwt/create/`
    const res = await this.$axios.$post(url, {
      email,
      password
    })
    return res
  },
  async loginProductlift({ commit }, payload) {
    const url = `${this.$config.apiUrlV2}/auth/jwt/productlift/create/`
    const res = await this.$axios.get(url)
    return res.data
  },
  async fetchDetail() {
    const url = `${this.$config.apiUrlV2}/user/me/`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async editAccount(store, { fullname, email, wizardState }) {
    try {
      const resEditAccount = await this.$axios.$patch(`/user/me/`, {
        fullname,
        email,
        wizard_state: wizardState
      })
      return resEditAccount
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async deleteAccount(store, payload) {
    try {
      const { password } = payload
      const resDeleteAccount = await this.$axios.$delete('/user/me/', {
        data: {
          password
        }
      })
      return resDeleteAccount
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async newPassword(store, payload) {
    try {
      const { currentPassword, newPassword } = payload
      const resNewPassword = await this.$axios.$post(
        '/user/me/change-password/',
        {
          current_password: currentPassword,
          new_password: newPassword
        }
      )
      return resNewPassword
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async newAvatar(store, avatarFile) {
    try {
      const formData = new FormData()
      formData.append('avatar', avatarFile)
      formData.append('type', 'file')
      const resNewAvatar = await this.$axios.$put(
        `/user/me/upload-avatar/`,
        formData
      )
      return resNewAvatar
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async verify(store, payload) {
    const { phone, govId, fullname, dateOfBirth, selfiePhoto } = payload
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/user/me/verification`,
        {
          phone,
          gov_id: govId,
          fullname,
          date_of_birth: dateOfBirth,
          selfie_photo: selfiePhoto,
          is_consent_given: true,
          consented_at: new Date().toISOString(),
          payment_terms_version: this.$config.paymentTermsVersion,
          verification_privacy_version: this.$config.verificationPrivacyVersion
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async manualVerify(store, payload) {
    const {
      phone,
      govId,
      fullname,
      dateOfBirth,
      selfieImage,
      govImage,
      bankAccountNumber,
      bankStatementImage,
      channelCode
    } = payload

    const formData = new FormData()
    formData.append('phone', phone)
    formData.append('gov_id', govId)
    formData.append('fullname', fullname)
    formData.append('date_of_birth', dateOfBirth)
    formData.append('selfie_image', selfieImage)
    formData.append('gov_image', govImage)
    formData.append('bank_account_number', bankAccountNumber)
    formData.append('bank_statement_image', bankStatementImage)
    formData.append('channel_code', channelCode)
    formData.append('is_consent_given', true)
    formData.append('consented_at', new Date().toISOString())
    formData.append('payment_terms_version', this.$config.paymentTermsVersion)
    formData.append(
      'manual_verification_privacy_version',
      this.$config.manualVerificationPrivacyVersion
    )

    try {
      const res = await this.$axios.$post(
        `/user/me/manual-verification/`,
        formData
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async updateTncPrivacy(store, payload) {
    const {
      baseTermsVersion,
      paymentTermsVersion,
      affiliateTermsVersion,
      basePrivacyVersion,
      verificationPrivacyVersion
    } = payload
    try {
      const res = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/user/me/tnc-privacy`,
        {
          base_terms_version: baseTermsVersion,
          payment_terms_version: paymentTermsVersion,
          affiliate_terms_version: affiliateTermsVersion,
          base_privacy_version: basePrivacyVersion,
          verification_privacy_version: verificationPrivacyVersion
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async getVerification(store) {
    try {
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/user/me/verification`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async buyQuota(store) {
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/user/me/verification-payment`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async userDetailFormBuilder(store) {
    const resUserDetail = await this.$prodApi.get('/v1/users/custom_profile/')
    return resUserDetail.data.data.user
  },
  async checkEmail({ commit, state }, email) {
    try {
      commit('SET_IS_EMAIL_CHECKING', true)
      commit('SET_EMAIL_EXIST_MSG', 'Checking')
      const resCheckEmail = await this.$axios.$get(
        `/user-search/check-email/?email=${email}`
      )
      commit('SET_IS_EMAIL_NOT_EXIST', resCheckEmail.data.is_available)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      commit('SET_IS_EMAIL_NOT_EXIST', false)
      throw error
    }
    commit(
      'SET_EMAIL_EXIST_MSG',
      state.isEmailNotExist ? '' : 'Alamat Email sudah terdaftar'
    )
    commit('SET_IS_EMAIL_CHECKING', false)
    return state.isEmailNotExist
  },
  async removeTelegram(_store) {
    try {
      const resEditAccount = await this.$axios.$patch(`/user/me/`, {
        telegram_chat_id: null
      })
      return resEditAccount
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },
  async blacklistTokens({ commit }, tokens) {
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/auth/jwt/blacklist`,
        {
          tokens
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
