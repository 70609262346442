//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import { onChangeCompanyBusiness } from '@/utils/helpers.js'

export default {
  name: 'ErrorScreen',
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 500,
        message: ''
      })
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      isCancel: false,
      isSuccess: true,
      isPermissionListModalShown: false,
      titleChangePlanConfirmModal: 'Berhenti Berlangganan',
      isAuthModalShown: false,
      nextPlan: null,
      publicRouteNames: [
        'order-public-secretSlug',
        'order-public-secretSlug-success',
        'balance-download-invoice-secret',
        'other-setting-billing-download-invoice-secret'
      ]
    }
  },
  head() {
    const { statusCode, message } = this.error
    let errorMessage = ''

    if (statusCode === 500) {
      errorMessage = 'Internal Server Error'
    } else {
      errorMessage = `${statusCode} - ${
        statusCode === 404 ? 'Page Not Found' : message
      }`
    }

    return {
      bodyAttrs: {
        class: 'bg-gray'
      },
      title: statusCode ? errorMessage : message
    }
  },
  computed: {
    ...mapGetters('common', [
      'getNavbarShow',
      'getBusinessAddModalShow',
      'getBusinessListSelectorShow'
    ]),
    ...mapGetters('subscription', [
      'getPricePlanShow',
      'getChangePlanConfirmShow'
    ]),
    getPermissions() {
      try {
        const permissionsList = cloneDeep(
          this.$auth.user.account.role.permissions_list
        )
        return permissionsList
      } catch {
        return []
      }
    },
    isBusinessShow: {
      get() {
        return this.getBusinessListSelectorShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessListSelectorShow', val)
      }
    },
    isAddModalShow: {
      get() {
        return this.getBusinessAddModalShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessAddModalShow', val)
      }
    },
    isPricePlanModalShow: {
      get() {
        return this.getPricePlanShow
      },
      set(val) {
        this.$store.dispatch('subscription/setPricePlanShow', val)
      }
    },
    isChangePlanConfirmModalShow: {
      get() {
        return this.getChangePlanConfirmShow
      },
      set(val) {
        this.$store.dispatch('subscription/setChangePlanConfirmShow', val)
      }
    },
    currentPlan() {
      return (
        this.$auth?.user?.detail.current_business_subscription
          ?.current_pricing_plan || null
      )
    }
  },
  watch: {
    // error: {
    //   handler() {
    //     if (process.browser) {
    //       const refreshToken = localStorage.getItem('auth._refresh.customLocal')
    //       if (!refreshToken || refreshToken === 'false') {
    //         location.reload()
    //       }
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // },
    '$route.path'(val, oldVal) {
      if (oldVal !== val) {
        this.$store.dispatch('common/setDesktopHeaderColor', '')
      }
    }
  },
  destroyed() {
    document.querySelector('#sclv-modal-wrapper').innerHTML = ''
  },
  mounted() {
    this.$store.dispatch(
      'subscription/putSubscription',
      this.$auth.user.detail.current_business_subscription
    )

    this.isPricePlanModalShow = false
    setTimeout(() => {
      if (
        ![
          'setting-billing',
          'setting-business',
          'setting-profile',
          'setting-profile-edit',
          'setting-profile-change-password'
        ].includes(this.$route.name) &&
        this.getPermissions.includes('edit_business')
      ) {
        this.$store.dispatch(
          'subscription/checkSubscriptionMiddleware',
          this.$auth.user.detail
        )
      }
    }, 100)
  },
  methods: {
    async reminderTargetUrl(resUpdateSubscription) {
      try {
        await Promise.all([
          this.$store.dispatch('xpTransaction/fetchBalance'),
          this.$store.dispatch('xpTransaction/fetchHoldingBalance')
        ])
      } catch {}

      const latestSubscriptionOrder =
        resUpdateSubscription?.latest_subscription_order
      if (
        latestSubscriptionOrder &&
        this.getBalance >= latestSubscriptionOrder.amount
      ) {
        return `/setting/billing?subscriptionOrderId=${latestSubscriptionOrder.id}`
      } else {
        return latestSubscriptionOrder.xendit_invoice_url
      }
    },
    onChangeCompanyBusiness,
    addBusinessSucceed() {
      this.isAddModalShow = false
      this.isSuccess = true
      this.onChangeCompanyBusiness()
    },
    yesCancel() {
      this.isCancel = false
      this.isAddModalShow = false
    },
    noCancel() {
      this.isCancel = false
      this.isBusinessAddModelShow = true
    },
    handleChoosePlan(nextPlan, changeType) {
      this.nextPlan = nextPlan
      if (['upgrade', 'activate'].includes(changeType)) {
        this.changePlan()
      } else {
        this.titleChangePlanConfirmModal = 'Pilih Paket Subscription'
        this.isChangePlanConfirmModalShow = true
      }
    },
    async changePlan() {
      this.$nuxt.$loading.start(true)
      try {
        let resUpdateSubscription = null
        const payloadPayment = {
          idSubscription:
            this.$auth.user?.detail.current_business_subscription?.id,
          pricingPlanCode: this.nextPlan.code
        }
        if (this.$auth.user?.detail.current_business_subscription) {
          resUpdateSubscription = await this.$store.dispatch(
            'subscription/partialUpdateSubscription',
            {
              idSubscription:
                this.$auth.user?.detail.current_business_subscription?.id,
              pricingPlanCode: this.nextPlan.code
            }
          )
        } else {
          resUpdateSubscription = await this.$store.dispatch(
            'subscription/storeData',
            payloadPayment
          )
        }

        if (
          resUpdateSubscription?.latest_subscription_order?.status === 'open'
        ) {
          window.location.href = await this.reminderTargetUrl(
            resUpdateSubscription
          )
        } else {
          window.location.href = `/setting/billing?isPricePlanShown=true`
        }
      } catch (error) {
        this.$errorHandler(error)
        this.$nuxt.$loading.finish()
      }
    },
    onCloseModalPricePlan() {
      this.isPricePlanModalShow = false
      this.$router.push('/setting/billing')
    }
  }
}
