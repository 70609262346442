import { render, staticRenderFns } from "./BusinessAccountPhone.vue?vue&type=template&id=51fb2cc4"
import script from "./BusinessAccountPhone.vue?vue&type=script&lang=js"
export * from "./BusinessAccountPhone.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@2.8.8_vue-temp_pxjgtvcgzwdiz5am2g337k7qpe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SclvAvatar: require('/app/components/common/SclvAvatar.vue').default,TextField: require('/app/components/common/TextField.vue').default,SclvButton: require('/app/components/common/SclvButton.vue').default,SclvForm: require('/app/components/common/SclvForm.vue').default})
