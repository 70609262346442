import {
  decodeQueryData,
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  couriers: [],
  metaCouriers: [],
  businessCouriers: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAllOnBusiness({ commit }) {
    try {
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/business/courier/`
      )
      commit('SET_BUSINESS_COURIERS', res.data)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeDataOnBusiness({ commit }, payload) {
    const { courierId, courierAggregatorId } = payload
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/business/courier/`,
        {
          courier_id: courierId,
          courier_aggregator_id: courierAggregatorId
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async editOnBusiness({ commit }, payload) {
    const { id, courierAggregatorId } = payload
    try {
      const res = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/business/courier/${id}`,
        {
          courier_aggregator_id: courierAggregatorId
        }
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async deleteOnBusiness({ commit }, id) {
    try {
      const res = await this.$axios.$delete(
        `${this.$config.apiUrlV2}/business/courier/${id}`
      )
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_COURIERS', [])
      commit('SET_META_COURIERS', [])
    }

    const { page, pageSize } = state.metaCouriers
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/courier/?${queries}`
    try {
      const resCourierList = await this.$axios.$get(url)
      const allCouriers = [...state.couriers, ...resCourierList.data.results]
      commit('SET_COURIERS', allCouriers)
      let newMeta = {
        itemsLength:
          resCourierList.data.count || resCourierList.data.results.length
      }
      const nextMeta = resCourierList.data.next
        ? decodeQueryData(resCourierList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_COURIERS', newMeta)
      return resCourierList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllExpanded({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_COURIERS', [])
      commit('SET_META_COURIERS', [])
    }

    const { page, pageSize } = state.metaCouriers
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/courier/expanded/?${queries}`
    try {
      const resCourierList = await this.$axios.$get(url)
      const allCouriers = [...state.couriers, ...resCourierList.data.results]
      commit('SET_COURIERS', allCouriers)
      let newMeta = {
        itemsLength:
          resCourierList.data.count || resCourierList.data.results.length
      }
      const nextMeta = resCourierList.data.next
        ? decodeQueryData(resCourierList.data.next.split('?')?.[1])
        : null
      if (nextMeta) {
        newMeta = {
          ...newMeta,
          page: nextMeta.page - 0,
          pageSize: nextMeta.page_size - 0
        }
      }
      commit('SET_META_COURIERS', newMeta)
      return resCourierList
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchByCode({ commit }, code) {
    const url = `/courier/expanded/?code=${code}`
    try {
      const resCourier = await this.$axios.$get(url)
      return resCourier
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
